/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */

import FooterLinksColumnOne from "./FooterLinksColumnOne";
import FooterLinksColumnTwo from "./FooterLinksColumnTwo";
import FooterLinksColumnThree from "./FooterLinksColumnThree";
import FooterLinksColumnFour from "./FooterLinksColumnFour";

const useStyles = makeStyles((theme) => ({
  linksAndContactMobile: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-start",
      width: "100%",
    },
  },
  linksAndContactTablet: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    [theme.breakpoints.only("sm")]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-start",
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  linksAndContactDesktop: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  addressMobile: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const FooterLinks = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.linksAndContactMobile}>
        <FooterLinksColumnOne />
        <FooterLinksColumnTwo />
      </div>
      <div className={classes.linksAndContactTablet}>
        <FooterLinksColumnOne />
        <FooterLinksColumnTwo />
        <FooterLinksColumnThree />
      </div>
      <div className={classes.addressMobile}>
        <FooterLinksColumnFour />
      </div>
      <div className={classes.linksAndContactDesktop}>
        <FooterLinksColumnOne />
        <FooterLinksColumnTwo />
        <FooterLinksColumnThree />
        <FooterLinksColumnFour />
      </div>
    </React.Fragment>
  );
};

export default FooterLinks;
