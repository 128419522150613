/* Common Imports */
import React from "react";
import { withRouter } from "next/router";
import makeStyles from "@material-ui/core/styles/makeStyles";

/* Component Imports */
import { Button, Menu, MenuItem } from "@material-ui/core";
import NavDropdownItem from "./NavDropdownMenuItem";

/* Data Imports */
import AppConfig from "../../../AppConfig";

const useStyles = makeStyles((theme) => ({
  navButtonActive: {
    borderBottomStyle: "solid",
    borderBottomWidth: "3px",
    borderImage: "-webkit-linear-gradient(0deg, #F74134, #EB1C61) 1",
    padding: "1.2rem 1rem 1rem 0rem",
    borderRadius: 0,
    fontWeight: "500",
    fontSize: "1rem",
    lineHeight: "1rem",
    letterSpacing: "0.047rem",
    textTransform: "capitalize",
    letterSpacing: "0.047rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  navButton: {
    color: "#666666",
    borderBottom: "solid 3px #FFFFFF",
    padding: "1.5rem 1rem 1.3rem 1rem",
    borderRadius: 0,
    fontWeight: "400",
    fontSize: "1rem",
    lineHeight: "1rem",
    letterSpacing: "0.047rem",
    textTransform: "capitalize",
    letterSpacing: "0.047rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  menu: {
    borderRadius: "5px",
    marginTop: "0.1rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    boxShadow:
      "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
    "& .MuiList-padding": {
      padding: 0,
    },
  },
  menuItem: {
    padding: "0rem",
    "&:hover": {
      background: "#f1f1f1",
    },
  },
  hideThis: {
    display: "none",
  },
  btnText: {
    marginLeft: "0.5rem",
  },
  btnTextActive: {
    color: "#cc0000",
    background: "-webkit-linear-gradient(0deg, #F74134, #EB1C61)",
    "-webkit-background-clip": "text",
    "-webkit-text-fillColor": "transparent",
    marginLeft: "0.5rem",
  },
}));

const DispenseIcon = (iconString) => {
  switch (iconString) {
    case "navbar/services/active":
      return "/images/icons/nav/services-red.svg";
    case "navbar/arrowdown":
      return "/images/icons/nav/arrowdown.svg";
    case "navbar/arrowdown/active":
      return "/images/icons/nav/arrowdown-red.svg";
    default:
      return "/images/icons/nav/company-red.svg";
  }
};

const NavDropdownMenuServices = (props) => {
  const classes = useStyles();
  const iconColor = props.router.pathname.startsWith(props.href)
    ? "active"
    : "inactive";

  const navIconClassActive = props.router.pathname.startsWith(props.href)
    ? ""
    : classes.hideThis;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Button
        aria-controls="services-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={
          props.router.pathname.startsWith(props.href)
            ? classes.navButtonActive
            : classes.navButton
        }
      >
        {/* Button Icon */}
        <img
          alt={AppConfig.imageAltText}
          loading="lazy"
          referrerPolicy="no-referrer"
          height={26}
          width={26}
          className={navIconClassActive}
          style={{ marginRight: "0.25rem", marginLeft: "0.7rem" }}
          src={DispenseIcon("navbar/services/active")}
        />

        {/* Button Text */}
        <span
          className={
            props.router.pathname.startsWith(props.href)
              ? classes.btnTextActive
              : classes.btnText
          }
        >
          {props.text}
        </span>

        {/* Arrow Down Icon */}
        <img
          alt={AppConfig.imageAltText}
          loading="lazy"
          referrerPolicy="no-referrer"
          height={13}
          width={13}
          style={{ marginRight: "0.1rem", marginLeft: "0.7rem" }}
          src={DispenseIcon(
            iconColor == "inactive"
              ? "navbar/arrowdown"
              : "navbar/arrowdown/active",
          )}
        />
      </Button>
      <Menu
        disableScrollLock
        id="services-menu"
        className={classes.menu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem className={classes.menuItem}>
          <NavDropdownItem
            href="/services/property-seekers"
            text="Property Seekers"
            icon="navbar/services/propertyseekers"
          />
        </MenuItem>
        <MenuItem className={classes.menuItem}>
          <NavDropdownItem
            href="/services/land-owners"
            text="Landowners"
            icon="navbar/services/landowners"
          />
        </MenuItem>
        <MenuItem className={classes.menuItem}>
          <NavDropdownItem
            href="/services/developers"
            text="Developers"
            icon="navbar/services/developers"
          />
        </MenuItem>
        <MenuItem className={classes.menuItem}>
          <NavDropdownItem
            href="/services/agents"
            text="Agents"
            icon="navbar/services/agents"
          />
        </MenuItem>
        <MenuItem className={classes.menuItem}>
          <NavDropdownItem
            href="/services/rewards"
            text="Rewards Program"
            icon="navbar/services/rewards"
          />
        </MenuItem>
        <MenuItem className={classes.menuItem}>
          <NavDropdownItem
            href="/services/nri"
            text="NRI Desk"
            icon="navbar/services/nri"
          />
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default withRouter(NavDropdownMenuServices);
