/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */

import { Link } from "@material-ui/core";

/* Data Imports */

import AppConfig from "../../../AppConfig";

const useStyles = makeStyles((theme) => ({
  socialLinkIcon: {
    margin: "0rem 0.5rem 0rem 0.5rem",
  },
}));

const FooterSocialIcons = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div>
        <Link
          href="//facebook.com/lighteradvisory"
          rel="noopener"
          title="Follow Lighteradvisory on Facebook"
          target="_blank"
        >
          <img
            src="/images/icons/social/facebook.svg"
            alt={AppConfig.imageAltText}
            width={32}
            height={32}
            loading="lazy"
            referrerPolicy="no-referrer"
            className={classes.socialLinkIcon}
          />
        </Link>
        <Link
          href="//twitter.com/lighteradvisory"
          rel="noopener"
          title="Follow Lighteradvisory on Twitter"
          target="_blank"
        >
          <img
            src="/images/icons/social/twitter.svg"
            alt={AppConfig.imageAltText}
            width={32}
            height={32}
            loading="lazy"
            referrerPolicy="no-referrer"
            className={classes.socialLinkIcon}
          />
        </Link>
        <Link
          href="//instagram.com/lighteradvisory"
          rel="noopener"
          title="Follow Lighteradvisory on Instagram"
          target="_blank"
        >
          <img
            src="/images/icons/social/instagram.svg"
            alt={AppConfig.imageAltText}
            width={32}
            height={32}
            loading="lazy"
            referrerPolicy="no-referrer"
            className={classes.socialLinkIcon}
          />
        </Link>
        {/* <Link
          href="//in.pinterest.com/lighteradvisory"
          rel="noopener"
          title="Follow Lighteradvisory on Pinterest"
          target="_blank"
        >
          <img
            src="/images/icons/social/pinterest.svg"
            alt={AppConfig.imageAltText}
            width={32}
            height={32}
            loading="lazy"
            referrerPolicy="no-referrer"
            className={classes.socialLinkIcon}
          />
        </Link> */}
        <Link
          href="//in.linkedin.com/company/lighteradvisory"
          rel="noopener"
          title="Follow Lighteradvisory on LinkedIn"
          target="_blank"
        >
          <img
            src="/images/icons/social/linkedin.svg"
            alt={AppConfig.imageAltText}
            width={32}
            height={32}
            loading="lazy"
            referrerPolicy="no-referrer"
            className={classes.socialLinkIcon}
          />
        </Link>
      </div>
    </React.Fragment>
  );
};

export default FooterSocialIcons;
