/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */

import { Link, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  regularColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    [theme.breakpoints.only("sm")]: {
      alignItems: "center",
    },
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-end",
      margin: "1rem 0rem 1rem 0rem",
    },
    flex: 1,
  },
  columnHeading: {
    width: "91%",
    /* XXS breakpoint */
    [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
      fontSize: "1rem",
      lineHeight: "1.5rem",
    },
    [theme.breakpoints.up("xs")]: {
      fontSize: "1.125rem",
      lineHeight: "1.6875rem",
    },
    fontWeight: 600,
    fontSize: "1.125rem",
    lineHeight: "1.6875rem",
    borderBottom: "1px solid #202020",
  },
  columnLink: {
    width: "91%",
    margin: "0.5rem 0rem 0.5rem 0rem",
    textDecoration: "none",
    fontWeight: 500,
    fontSize: "1rem",
    lineHeight: "1.5rem",
    color: "#000000",
    "&:hover": {
      color: "#CC0000",
      background: "-webkit-linear-gradient(#F74134, #EB1C61)",
      "-webkit-background-clip": "text",
      "-webkit-text-fillColor": "transparent",
    },
  },
}));

const FooterLinksColumnTwo = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.regularColumn}>
        <Typography
          variant="body2"
          component="span"
          className={classes.columnHeading}
        >
          Get Help
        </Typography>
        <Link
          href="/company/contact-us"
          rel="noopener"
          title="Contact Us"
          className={classes.columnLink}
        >
          Contact Us
        </Link>
        <Link
          href="/company/faqs"
          rel="noopener"
          title="FAQs"
          className={classes.columnLink}
        >
          FAQs
        </Link>
        <Link
          href="/postrequirement"
          rel="noopener"
          title="Post Requirement"
          className={classes.columnLink}
        >
          Post Requirement
        </Link>
        <Link
          href="/privacy"
          rel="noopener"
          title="Privacy Policy"
          className={classes.columnLink}
        >
          Privacy Policy
        </Link>
        <Link
          href="/terms"
          rel="noopener"
          title="Terms of Use"
          className={classes.columnLink}
        >
          Terms of Use
        </Link>
      </div>
    </React.Fragment>
  );
};

export default FooterLinksColumnTwo;
