/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */

import { Link, Typography } from "@material-ui/core";

/* Data Imports */

import AppConfig from "../../../AppConfig";

const useStyles = makeStyles((theme) => ({
  addressColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      margin: "1rem 0rem 1rem 0rem",
    },
    flex: 1.2,
  },
  columnHeading: {
    width: "100%",
    /* XXS breakpoint */
    [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
      fontSize: "1rem",
      lineHeight: "1.5rem",
    },
    [theme.breakpoints.up("xs")]: {
      fontSize: "1.125rem",
      lineHeight: "1.6875rem",
    },
    fontWeight: 600,
    fontSize: "1.125rem",
    lineHeight: "1.6875rem",
    borderBottom: "1px solid #202020",
  },
  addressLink: {
    margin: "0.5rem 0rem 0.5rem 0rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      margin: "1rem 0rem 1rem 0rem",
    },
    width: "100%",
    textDecoration: "none",
    fontWeight: 500,
    fontSize: "1rem",
    lineHeight: "1.5rem",
    color: "#000000",
    "&:hover": {
      color: "#CC0000",
      background: "-webkit-linear-gradient(#F74134, #EB1C61)",
      "-webkit-background-clip": "text",
      "-webkit-text-fillColor": "transparent",
    },
  },
  linkIcon: {
    marginRight: "0.5rem",
  },
}));

const FooterLinksColumnFour = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.addressColumn}>
        <Typography
          variant="body2"
          component="span"
          className={classes.columnHeading}
        >
          Get in touch
        </Typography>
        <Link
          href="https://g.page/beegru?share"
          rel="noopener"
          title="Open in Google Maps"
          target="_blank"
          className={classes.addressLink}
        >
          <img
            src="/images/icons/social/location.svg"
            alt={AppConfig.imageAltText}
            width={32}
            height={32}
            loading="lazy"
            referrerPolicy="no-referrer"
            className={classes.linkIcon}
          />
          <span>
            1090i, 3<sup>rd</sup> Floor, 18<sup>th</sup> Cross Road, 3
            <sup>rd</sup> Sector, HSR Layout, Bengaluru, Karnataka 560 102
          </span>
        </Link>
        <Link
          href={"tel:" + AppConfig.tollFreeNumber}
          rel="noopener"
          title="Call Lighter Advisory"
          className={classes.addressLink}
        >
          <img
            src="/images/icons/social/phone.svg"
            alt={AppConfig.imageAltText}
            width={32}
            height={32}
            loading="lazy"
            referrerPolicy="no-referrer"
            className={classes.linkIcon}
          />
          1800 572 0999
        </Link>
        <Link
          href="https://wa.me/+919980775599"
          rel="noopener"
          title="WhatsApp Lighter Advisory"
          target="_blank"
          className={classes.addressLink}
        >
          <img
            src="/images/icons/social/whatsapp.svg"
            alt={AppConfig.imageAltText}
            width={32}
            height={32}
            loading="lazy"
            referrerPolicy="no-referrer"
            className={classes.linkIcon}
          />
          +91 99807 75599
        </Link>
        <Link
          href={"mailto:" + AppConfig.email}
          rel="noopener"
          title="Email Lighter Advisory"
          target="_blank"
          className={classes.addressLink}
        >
          <img
            src="/images/icons/social/email.svg"
            alt={AppConfig.imageAltText}
            width={32}
            height={32}
            loading="lazy"
            referrerPolicy="no-referrer"
            className={classes.linkIcon}
          />
          marketing@lighteradvisory.com
        </Link>
      </div>
    </React.Fragment>
  );
};

export default FooterLinksColumnFour;
