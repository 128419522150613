const MODE = process.env.mode;

var tempAc = {};

MODE == "development"
  ? (tempAc = {
      urlPrefix: process.env.baseUrlDevelopment,
      apiUrlPrefix: process.env.baseUrlDevelopment + "api/",
      blogUrlPrefix: process.env.baseUrlDevelopment + "blogs/",
      alternateUrlPrefix: process.env.alternateBaseUrlDevelopment,
      alternateApiUrlPrefix: process.env.alternateBaseUrlDevelopment + "api/",
      alternateBlogUrlPrefix:
        process.env.alternateBaseUrlDevelopment + "blogs/",
      twilioWhatsAppNumber: "whatsapp:+14155824623",
      boldRedDelimiter: "~",
      imageAltText: "Unable to load image",
      tollFreeNumber: "18005720999",
      email: "mail@beegru.com",
      maxChipsPropertyCard: 4,
      slackLeadsWebhook:
        "https://hooks.slack.com/services/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",
      slackPostRequirementLeadsWebhook:
        "https://hooks.slack.com/services/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",
      slackServiceLeadsWebhook:
        "https://hooks.slack.com/services/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",
      gcpProjectId: "beegru-com",
      googleOAuthClientId:
        "1086191136399-8dmpbmaurrnpgn8tket6v5jgf4qo9977.apps.googleusercontent.com",
      googleOAuthClientSecret: "vcfn_MEgGCEApaTzeTuRopwe",
    })
  : (tempAc = {
      urlPrefix: process.env.baseUrlProduction,
      apiUrlPrefix: process.env.baseUrlProduction + "api/",
      blogUrlPrefix: process.env.baseUrlProduction + "blogs/",
      alternateUrlPrefix: process.env.alternateBaseUrlProduction,
      alternateApiUrlPrefix: process.env.alternateBaseUrlProduction + "api/",
      alternateBlogUrlPrefix: process.env.alternateBaseUrlProduction + "blogs/",
      twilioWhatsAppNumber: "whatsapp:+14155824623",
      boldRedDelimiter: "~",
      imageAltText: "Unable to load image",
      tollFreeNumber: "18005720999",
      email: "mail@beegru.com",
      maxChipsPropertyCard: 4,
      slackLeadsWebhook:
        "https://hooks.slack.com/services/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",
      slackPostRequirementLeadsWebhook:
        "https://hooks.slack.com/services/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",
      slackServiceLeadsWebhook:
        "https://hooks.slack.com/services/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",
      gcpProjectId: "beegru-com",
      googleOAuthClientId:
        "1086191136399-8dmpbmaurrnpgn8tket6v5jgf4qo9977.apps.googleusercontent.com",
      googleOAuthClientSecret: "vcfn_MEgGCEApaTzeTuRopwe",
    });
const AppConfig = tempAc;

export default AppConfig;
