/* Common Imports */
import React from "react";
import { withRouter } from "next/router";
import makeStyles from "@material-ui/core/styles/makeStyles";

/* Component Imports */
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@material-ui/core";
import SideNavDropdownItem from "./SideNavDropdownItem";

/* Data Imports */
import AppConfig from "../../../AppConfig";

const useStyles = makeStyles((theme) => ({
  sideNavButton: {
    color: "#757575",
    background: "#ffffff",
  },
  sideNavButtonActive: {
    color: "#CC0000",
    background: "-webkit-linear-gradient(0deg, #F74134, #EB1C61)",
    "-webkit-background-clip": "text",
    "-webkit-text-fillColor": "transparent",
    borderRightStyle: "solid",
    borderRightWidth: "3px",
    borderImage: "-webkit-linear-gradient(270deg, #F74134, #EB1C61) 1",
  },
  listIcon: {
    minWidth: "auto",
  },
  sideNavButtonText: {
    fontWeight: "400",
    fontSize: "1rem",
    lineHeight: "1.5rem",
  },
  sideNavButtonTextActive: {
    color: "#CC0000",
    background: "-webkit-linear-gradient(0deg, #F74134, #EB1C61)",
    "-webkit-background-clip": "text",
    "-webkit-text-fillColor": "transparent",
    fontWeight: "500",
    fontSize: "1rem",
    lineHeight: "1.5rem",
  },
}));

const DispenseIcon = (iconString) => {
  switch (iconString) {
    case "navbar/company":
      return "/images/icons/nav/company.svg";
    case "navbar/company/active":
      return "/images/icons/nav/company-red.svg";
    case "navbar/arrowdown":
      return "/images/icons/nav/arrowdown.svg";
    case "navbar/arrowdown/active":
      return "/images/icons/nav/arrowdown-red.svg";
    case "navbar/arrowup":
      return "/images/icons/nav/arrowup.svg";
    case "navbar/arrowup/active":
      return "/images/icons/nav/arrowup-red.svg";
    default:
      return "/images/icons/nav/home.svg";
  }
};

const SideNavDropdownCompany = (props) => {
  const classes = useStyles();
  const iconColor = props.router.pathname.startsWith(props.href)
    ? "active"
    : "inactive";

  const [open, setOpen] = React.useState(props.open ? props.open : false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      {/* SideNav Button */}
      <ListItem
        button
        onClick={handleClick}
        className={
          props.router.pathname.startsWith(props.href)
            ? classes.sideNavButtonActive
            : classes.sideNavButton
        }
      >
        {/* Button Icon */}
        <ListItemIcon className={classes.listIcon}>
          <img
            alt={AppConfig.imageAltText}
            loading="lazy"
            referrerPolicy="no-referrer"
            height={24}
            width={24}
            style={{ marginRight: "0.5rem" }}
            src={DispenseIcon(
              iconColor == "inactive"
                ? "navbar/company"
                : "navbar/company/active",
            )}
          />
        </ListItemIcon>

        {/* Button Text */}
        <ListItemText
          primary={props.text}
          primaryTypographyProps={{
            className: props.router.pathname.startsWith(props.href)
              ? classes.sideNavButtonTextActive
              : classes.sideNavButtonText,
          }}
        />

        {/* Arrow up/down icons  */}
        <img
          alt={AppConfig.imageAltText}
          loading="lazy"
          referrerPolicy="no-referrer"
          height={14}
          width={14}
          style={{ marginRight: "0.25rem" }}
          src={DispenseIcon(
            open
              ? iconColor == "inactive"
                ? "navbar/arrowup"
                : "navbar/arrowup/active"
              : iconColor == "inactive"
              ? "navbar/arrowdown"
              : "navbar/arrowdown/active",
          )}
        />
      </ListItem>

      {/* Dropdown List */}
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <SideNavDropdownItem
            href="/company/about-us"
            text="About Us"
            icon="navbar/company/aboutus"
          />
          <SideNavDropdownItem
            href="/company/faqs"
            text="FAQs"
            icon="navbar/company/faqs"
          />
          <SideNavDropdownItem
            href="/company/careers"
            text="Careers"
            icon="navbar/company/careers"
          />
          <SideNavDropdownItem
            href="/company/blogs"
            text="Blog"
            icon="navbar/company/blog"
          />
          <SideNavDropdownItem
            href="/company/contact-us"
            text="Contact Us"
            icon="navbar/company/contactus"
          />
        </List>
      </Collapse>
    </React.Fragment>
  );
};

export default withRouter(SideNavDropdownCompany);
