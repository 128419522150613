/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */

import { Link, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  regularColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    [theme.breakpoints.only("sm")]: {
      alignItems: "flex-end",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
      margin: "1rem 0rem 1rem 0rem",
    },
    flex: 1,
  },
  columnHeading: {
    width: "91%",
    /* XXS breakpoint */
    [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
      fontSize: "1rem",
      lineHeight: "1.5rem",
    },
    [theme.breakpoints.up("xs")]: {
      fontSize: "1.125rem",
      lineHeight: "1.6875rem",
    },
    fontWeight: 600,
    fontSize: "1.125rem",
    lineHeight: "1.6875rem",
    borderBottom: "1px solid #202020",
  },
  columnLink: {
    width: "91%",
    margin: "0.5rem 0rem 0.5rem 0rem",
    textDecoration: "none",
    fontWeight: 500,
    fontSize: "1rem",
    lineHeight: "1.5rem",
    color: "#000000",
    "&:hover": {
      color: "#CC0000",
      background: "-webkit-linear-gradient(#F74134, #EB1C61)",
      "-webkit-background-clip": "text",
      "-webkit-text-fillColor": "transparent",
    },
  },
}));

const FooterLinksColumnThree = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.regularColumn}>
        <Typography
          variant="body2"
          component="span"
          className={classes.columnHeading}
        >
          Our Services
        </Typography>
        <Link
          href="/services/property-seekers"
          rel="noopener"
          title="Property Seekers"
          className={classes.columnLink}
        >
          Property Seekers
        </Link>
        <Link
          href="/services/land-owners"
          rel="noopener"
          title="Land Owners"
          className={classes.columnLink}
        >
          Land Owners
        </Link>
        <Link
          href="/services/developers"
          rel="noopener"
          title="Developers"
          className={classes.columnLink}
        >
          Developers
        </Link>
        <Link
          href="/services/agents"
          rel="noopener"
          title="Agents"
          className={classes.columnLink}
        >
          Agents
        </Link>
        <Link
          href="/services/rewards"
          rel="noopener"
          title="Rewards Program"
          className={classes.columnLink}
        >
          Rewards Program
        </Link>
      </div>
    </React.Fragment>
  );
};

export default FooterLinksColumnThree;
