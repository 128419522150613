/* Common Imports */
import React from "react";
import { withRouter } from "next/router";
import makeStyles from "@material-ui/core/styles/makeStyles";

/* Component Imports */
import { Link } from "@material-ui/core";

/* Data Imports */
import AppConfig from "../../../AppConfig";

const useStyles = makeStyles((theme) => ({
  link: {
    "&:hover": {
      textDecoration: "none",
    },
    padding: "1rem",
    width: "100%",
  },
  navButtonActive: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    borderRadius: 0,
    fontWeight: "500",
    fontSize: "1rem",
    lineHeight: "1rem",
    letterSpacing: "0.047rem",
    textTransform: "capitalize",
  },
  navButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    color: "#666666",
    borderRadius: 0,
    fontWeight: "400",
    fontSize: "1rem",
    lineHeight: "1rem",
    letterSpacing: "0.047rem",
    textTransform: "capitalize",
  },
  hideThis: {
    display: "none",
  },
  marginLeft: {
    marginLeft: "0.5rem",
  },
  marginLeftActive: {
    color: "#cc0000",
    background: "-webkit-linear-gradient(0deg, #F74134, #EB1C61)",
    "-webkit-background-clip": "text",
    "-webkit-text-fillColor": "transparent",
    marginLeft: "0.5rem",
  },
}));

const DispenseIcon = (iconString) => {
  switch (iconString) {
    case "navbar/services/propertyseekers":
      return "/images/icons/nav/propertyseekers.svg";
    case "navbar/services/propertyseekers/active":
      return "/images/icons/nav/propertyseekers-red.svg";
    case "navbar/services/landowners":
      return "/images/icons/nav/landowners.svg";
    case "navbar/services/landowners/active":
      return "/images/icons/nav/landowners-red.svg";
    case "navbar/services/developers":
      return "/images/icons/nav/developers.svg";
    case "navbar/services/developers/active":
      return "/images/icons/nav/developers-red.svg";
    case "navbar/services/agents":
      return "/images/icons/nav/agents.svg";
    case "navbar/services/agents/active":
      return "/images/icons/nav/agents-red.svg";
    case "navbar/services/rewards":
      return "/images/icons/nav/rewards.svg";
    case "navbar/services/rewards/active":
      return "/images/icons/nav/rewards-red.svg";
    case "navbar/services/nri":
      return "/images/icons/nav/nri.svg";
    case "navbar/services/nri/active":
      return "/images/icons/nav/nri-red.svg";
    case "navbar/company/aboutus":
      return "/images/icons/nav/aboutus.svg";
    case "navbar/company/aboutus/active":
      return "/images/icons/nav/aboutus-red.svg";
    case "navbar/company/faqs":
      return "/images/icons/nav/faqs.svg";
    case "navbar/company/faqs/active":
      return "/images/icons/nav/faqs-red.svg";
    case "navbar/company/careers":
      return "/images/icons/nav/careers.svg";
    case "navbar/company/careers/active":
      return "/images/icons/nav/careers-red.svg";
    case "navbar/company/blog":
      return "/images/icons/nav/blog.svg";
    case "navbar/company/blog/active":
      return "/images/icons/nav/blog-red.svg";
    case "navbar/company/contactus":
      return "/images/icons/nav/contactus.svg";
    case "navbar/company/contactus/active":
      return "/images/icons/nav/contactus-red.svg";
    default:
      return "/images/icons/nav/home.svg";
  }
};

const NavDropdownItem = (props) => {
  const classes = useStyles();
  const iconColor = props.router.pathname == props.href ? "active" : "inactive";
  return (
    <React.Fragment>
      <Link
        href={props.href}
        rel="noopener"
        aria-label={props.text}
        title={props.text}
        className={classes.link}
      >
        <div
          className={
            props.router.pathname == props.href
              ? classes.navButtonActive
              : classes.navButton
          }
        >
          {/* Icon */}
          <img
            alt={AppConfig.imageAltText}
            loading="lazy"
            referrerPolicy="no-referrer"
            height={26}
            width={26}
            style={{ marginRight: "0.25rem" }}
            src={DispenseIcon(
              iconColor == "inactive" ? props.icon : props.icon + "/active",
            )}
          />

          {/* Button Text */}
          <span
            className={
              props.router.pathname == props.href
                ? classes.marginLeftActive
                : classes.marginLeft
            }
          >
            {props.text}
          </span>
        </div>
      </Link>
    </React.Fragment>
  );
};

export default withRouter(NavDropdownItem);
