/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */

import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  copyrightInfo: {
    // [theme.breakpoints.down("sm")]: {
    //   textAlign: "center",
    // },
  },
  copyrightDesktop: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "1.5rem",
  },
  copyrightMobile: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "1.5rem",
  },
}));

const FooterCopyrightInfo = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.copyrightInfo}>
        <Typography
          variant="body2"
          component="span"
          className={classes.copyrightDesktop}
        >
          &copy; {new Date().getFullYear()} Lighter Advisory Private Limited.
          All Rights Reserved.
        </Typography>
        <Typography
          variant="body2"
          component="span"
          className={classes.copyrightMobile}
        >
          &copy; {new Date().getFullYear()} LAPL. All Rights Reserved.
        </Typography>
      </div>
    </React.Fragment>
  );
};

export default FooterCopyrightInfo;
