/* Common Imports */

import React from "react";

/* Component Imports */

import BeegruContainer from "./BeegruContainer";
import FooterHeader from "./footer-components/FooterHeader";
import FooterLinks from "./footer-components/FooterLinks";
import FooterCopyrightInfo from "./footer-components/FooterCopyrightInfo";

const Footer = () => {
  return (
    <React.Fragment>
      <div
        style={{
          padding: "1rem 0rem 0rem 0rem",
          backgroundColor: "#FAFAFA",
          boxShadow:
            "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
        }}
      >
        <BeegruContainer>
          <FooterHeader />
          <FooterLinks />
          <FooterCopyrightInfo />
        </BeegruContainer>
      </div>
    </React.Fragment>
  );
};

export default Footer;
