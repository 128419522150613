/* React Imports */

import React from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "next/router";
// import Script from "next/script";

/* Component Imports */

import Header from "./Header";
import Footer from "./Footer";
import Navbar from "./Navbar";
import Fabs from "./Fabs";
import BeegruContainer from "./BeegruContainer";

/* Data Imports */
import AppConfig from "../../AppConfig";

const Layout = (props) => {
  return (
    <React.Fragment>
      <div id="rootDiv">
        <Header
          keywords={
            props.keywords
              ? props.keywords
              : "lighter Advisory,real,estate,advisory,pwa,next,property,bangalore,bengaluru,home,house,apartment,land,plot,rent,buy,sell,agriculture,farm,construction,architect,architecture,interior,design,material,ui,township,react"
          }
          description={
            props.description
              ? props.description
              : "Lighter Advisory, your one stop property shop. Experience Bengaluru's real estate world with our professional real estate services."
          }
          title={props.title ? props.title : "Lighter Advisory"}
          canonicalUrl={
            props.canonicalUrl
              ? props.canonicalUrl
              : AppConfig.urlPrefix + props.router.asPath.substring(1)
          }
        />
        {!props.hideNavbar ? (
          <Navbar
            navbarElevation={
              props.navbarElevation !== undefined ? props.navbarElevation : 4
            }
            onScrollTrigger={
              typeof props.onScrollTrigger === "function"
                ? props.onScrollTrigger
                : undefined
            }
          />
        ) : undefined}
        <Helmet>
          <html lang="en-GB" />
        </Helmet>
        <BeegruContainer>{props.children}</BeegruContainer>
        {!props.hideFabs ? <Fabs /> : undefined}
        {props.hideFooter ? undefined : <Footer id="footer" />}
        {/* <Script
          type="application/ld+json"
          dangerouslySetInnerHTML={
            props.jsonLDSchema
              ? props.jsonLDSchema
              : {
                  __html: JSON.stringify({
                    "@context": "https://schema.org/",
                    "@type": "RealEstateListing",
                    name: "Party Coffee Cake",
                    author: {
                      "@type": "Person",
                      name: "Mary Stone",
                    },
                    datePublished: "2018-03-10",
                    description:
                      "This coffee cake is awesome and perfect for parties.",
                    prepTime: "PT20M",
                  }),
                }
          }
        /> */}
      </div>
    </React.Fragment>
  );
};

export default withRouter(Layout);
